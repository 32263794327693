<!-- terms-and-conditions.component.html -->

<div class="container pd-50" style="padding: inherit; margin-top: 10vh ">
    <div class="terms-and-conditions">
        <h2>Terms and Conditions</h2>
      
        <div class="section">
          <h3>1. Booking Process</h3>
          <p>You can book our services either through our website or by contacting our customer service centre. Booking must be made at least 12 hours in advance.</p>
        </div>
      
        <div class="section">
          <h3>2. Fare and Pricing Policy</h3>
          <p>How we charge for one-way trips as well as round trips is based on the distance that costs you the least. Criteria used to determine fares such as distance (per km) and Additional fees(Toll, Parking, State to State Permit, Hills charges, Airport charges, Night driver allowance 11 pm-5 am). There shall be extra charges for some off-locations and out-of-town as well. One-way fares are likely to increase on special days and weekends.</p>
        </div>
      
        <div class="section">
          <h3>3. Payment Terms</h3>
          <ul>
            <li>Payment options available such as online payment UPI (Gpay, Phonepay, Paytm, and other)</li>
            <li>On each booking, a cash payment of 15% of the total fare should be paid for confirmation.</li>
            <li>On completion of your trip, the balance amount can be paid to the driver or office.</li>
          </ul>
        </div>
      
        <div class="section">
          <h3>4. Booking Cancellation Policy</h3>
          <p>You can cancel your booking at any time before the pickup time. In case of cancellation, a cancellation fee may apply.</p>
        </div>
      
        <div class="section">
          <h3>5. Service Availability</h3>
          <p>Specify the geographic areas and hours of operation for the taxi service, and outline any conditions that may affect availability, such as weather, traffic, or road closures.</p>
        </div>
      
        <div class="section">
          <h3>6. Driver and Vehicle Standards</h3>
          <p>Our drivers are well-trained and experienced, and our vehicles are well-maintained for your safety and comfort.</p>
        </div>
      
        <div class="section">
          <h3>7. Passenger Responsibility</h3>
          <p>One Drop Cab Service will not be responsible for any loss, damage, or theft of personal belongings during the trip.</p>
        </div>
      
        <div class="section">
          <h3>8. Changes to Terms and Conditions</h3>
          <p>One Drop Cab Service reserves the right to change these terms and conditions at any time without prior notice.</p>
        </div>
      
        <div class="section">
          <h3>9. Governing Law</h3>
          <p>These terms and conditions will be governed by and construed by the laws of the Republic of India.</p>
        </div>
      </div>
</div>
  